@import ../../../../../base/config/Config

.homeArea
    width: 100%
    background-color: var(--blue)

.homeMessageArea
    display: inline-block
    max-width: 924px
    margin-top: 25vh

.homeMessageArea > *
    margin-left: 24px
    margin-right: 24px
    margin-bottom: 36px

.homeMessage
    display: inline-block
    max-width: 656px

.homeMessage > *
    margin-bottom: 8px

.homeButtonArea
    margin-top: 12px

.homeButtonArea > *
    margin-left: 10px
    margin-right: 10px

.hometextArea
    margin-top: 36px
    margin-bottom: 253px

.hometextArea > *
    display: inline-block
    margin-left: 12px
    margin-right: 12px

.homeText > *
    display: inline-block

.homeText img
    margin-right: 8px

.homeImageLeftArea
    display: inline-block
    float: left
    margin-top: -25%
    margin-left: 24px

.homeImageRightArea
    display: inline-block
    margin-top: -25%
    float: right
    margin-right: 24px
    margin-bottom: 45px

.homeAreaBottom
    display: inline-block
    background-color: var(--darkWhite)
    width: 100%

.homeLogoArea .lazyload-wrapper > *
    mix-blend-mode: luminosity
    opacity: 0.6
    max-height: 70px
    vertical-align: middle
    margin-right: 75px
    margin-left: 75px
    margin-top: 32px
    margin-bottom: 32px

@media screen and ( max-width: 970px  )

    .hometextArea
        margin-bottom: 150px

    .homeImageLeftArea
        width: 45%
        position: relative
        margin-left: 12px

    .homeImageLeftArea > *
        width: 100%
        height: 100%
        margin-bottom: -100px

    .homeImageRightArea
        width: 45%
        position: relative
        margin-right: 12px

    .homeImageRightArea > *
        width: 100%
        height: 100%
        margin-bottom: -100px

    .homeLogoArea
        margin-top: 80px


@media screen and ( max-width: 748px  )
    .homeMessageArea
        max-width: 924px
        margin-top: 18vh

    .homeLogoArea .lazyload-wrapper > *
        margin-top: 17px
        margin-left: 50px
        margin-right: 50px
        margin-bottom: 17px
        height: 30px

    .homeButtonArea > *
        margin-left: 10px
        margin-right: 10px

@import ../config/Config.sass

.App
    text-align: center

.Blue
    color: var(--blue)

a
    text-decoration: none

.BannerArea
    position: sticky
    bottom: 0
    z-index: 100

.CookieArea
    z-index: 101
    box-shadow: 0px -1px 4px #00000029
    background: #FFFFFF
    position: absolute
    bottom: 0
    padding: 8px 24px
    display: flex
    align-items: center
    width: calc(100% - 48px)

.CookieTextArea
    flex: 1
    margin-right: 24px

.CookieButtonArea
    display: flex
    align-items: center
    flex: 0 0 auto

.CookieRemove
    cursor: pointer
    width: 30px
    height: 30px

.CookieButtonArea .Button.SecundaryPricingButton
    padding: 8px 16px
    font-size: 12px
    margin-right: 48px

.PromoArea
    z-index: 100
    background-color: #331C69
    width: 100%
    position: sticky
    bottom: 0

.PromoTextArea
    display: inline-block
    margin: 16px 0px

.PromoTextArea span
    font-family: var(--sofiaPro)
    font-weight: bold
    color: #16E0A4
    text-transform: uppercase

.PromoButtonArea
    position: absolute
    top: 10px
    right: 24px

.PromoButtonArea .Button.Secundary
    color: #331C69
    padding: 8px 16px
    font-size: 12px

@media screen and ( max-width: 675px )
    .CookieArea
        display: block
        padding: 16px 24px

    .CookieTextArea
        flex: 1
        margin-right: 0px

    .CookieButtonArea
        margin-top: 16px
        display: flex
        align-items: center
        justify-content: center
        flex: 1

    .CookieButtonArea .Button.SecundaryPricingButton
        margin-right: 0px

    .CookieRemove
        display: none
        visibility: collapse

    .PromoArea > *
        display: block

    .PromoButtonArea
        position: relative
        padding-bottom: 16px
        top: 0px
        right: 0px

    .PromoTextArea
        margin: 0px 0px
        padding: 16px 5px

@import ../../../base/config/Config

.Button
    display: inline-block
    position: relative
    cursor: pointer
    border-radius: 50px
    font-size: 18px
    font-family: var(--openSans)

.Button.Giant
    padding: 10px 148px

.Button.Big
    padding: 24px 48px
    font-weight: bold

.Button.Medium
    padding: 16px 36px
    font-weight: bold

.Button.Small
    padding: 10px 24px

.Button.Primary
    background-color: var(--blue)
    color: var(--white)

.Button.Secundary
    background-color: var(--white)
    color: var(--blue)

.Button.Tertiary
    background-color: var(--yellow)
    color: var(--black)

.Button.Transparent
    color: var(--white)
    padding-left: 0px

.Button.TransparentSecundary
    color: var(--blue)
    padding-left: 0px

.Button.TransparentTertiary
    color: var(--darkerGrey)
    padding-left: 0px

.Button.Transparentfourth
    color: var(--black)

.Button.TransparentFifth
    color: var(--darkPurple)

.Button.TransparentHover
    color: var(--black)
    padding-left: 0px
    &:hover
        color: var(--blue)

.Button.UnderlineTransparent
    color: var(--white)
    text-decoration: underline

.Button.UnderlineTransparentSecundary
    color: var(--black)
    text-decoration: underline

.Button.PrimaryPricingButton
    color: var(--blue)
    border-color: var(--blue)
    border: 1px solid

.Button.SecundaryPricingButton
    background-color: var(--purple)
    color: var(--white)

.Button.Academy
    background-color: var(--acedamyPurple)
    color: var(--white)
    border-radius: 4px
    font-weight: 500
    width: 100%
    padding: 15px 0px
    max-width: 375px
    font-family: Halyard Display Medium
    letter-spacing: 1.6px

.Button.SofiaProBlack, .Button.SofiaProBlackActive
    font-size: 32px
    font-family: var(--sofiaPro)
    font-weight: 800

.Button.SofiaProBlack
    color: var(--black)

.Button.SofiaProBlackActive
    color: var(--blue)

.Button.SofiaProBlackSmall, .Button.SofiaProBlackSmallActive
    font-size: 24px
    font-family: var(--sofiaPro)
    font-weight: 800

.Button.SofiaProBlackSmall
    color: var(--black)

.Button.SofiaProBlackSmallActive
    color: var(--blue)

.Button.typeClientButton
    font-size: 24px
    font-family: var(--sofiaPro)
    font-weight: 800
    color: var(--black)

.Button.typeClientButtonActive
    font-size: 24px
    font-family: var(--sofiaPro)
    font-weight: 800
    color: var(--blue)

    &:after
        content: ""
        position: absolute
        left: 75px
        bottom: 15px
        width: calc(100% - 105px)
        height: 8px
        background-color: var(--yellow)
        border-radius: 5px

.Button.MonsterratBlack
    color: var(--black)
    font-size: 16px
    padding-left: 0px
    font-family: var(--montserrat)

.Button.SmallBlue
    font-size: 16px
    color: var(--blue)

.Button.Footer
    display: flex
    align-items: center
    color: var(--darkerGrey)
    font-size: 16px
    font-weight: 400
    font-family: var(--sofiaPro)
    padding-left: 0px

.ButtonIcon.white
    #copy
        fill: var(--white)

.ButtonIcon.black
    #copy
        fill: var(--black)

.Button.Overflow
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

@media screen and ( max-width: 450px )
    .Button.typeClientButton, .Button.typeClientButtonActive
        font-size: 16px

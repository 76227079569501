@import ../../../base/config/Config

.Dropdown
    position: relative
    display: inline-block

.fadeIn
    visibility: visible

.fadeOut
    visibility: collapse

.Dropdown.Secundary 
    width: 100%
    max-width: 400px

.Dropdown.Secundary .DropdownButtonArea
    display: inline-block
    cursor: pointer
    width: 100%

.Dropdown.Secundary .dropdownButtonChervonDown, .Dropdown.Secundary .dropdownButtonChervonUp
    position: absolute
    right: 25px
    top: 0
    width: 20px
    height: 12px
    margin-top: 15px

.Dropdown.Secundary .dropdownButtonChervonDown
    background: var(--chevronDown)
    transform: rotate(360deg)
    transition: 0.5s transform
    
.Dropdown.Secundary .dropdownButtonChervonUp
    background: var(--chevronUp)
    transform: rotate(180deg)
    transition: 0.5s transform

.Dropdown.Primary .DropdownLine
    position: relative
    margin-left: 35%
    height: 4px
    background: var(--yellow)
    border-radius: 40px
    text-align: center
    animation: grow 0.2s forwards

.Dropdown.Primary .dropdownButtonChervonDown, .Dropdown.Primary .dropdownButtonChervonUp
    display: none
    visibility: collapse

.Dropdown.Primary .DropdownContentIn
    display: block
    position: absolute
    
.Dropdown.Secundary .DropdownContentIn
    display: block
    visibility: visible
    position: relative
    width: 100%
    height: auto
    background-color: var(--white)
    z-index: 1

.DropdownContentOut
    display: none
    visibility: collapse

.Dropdown.Secundary .DropdownContentIn > *
    display: block
    margin-bottom: 24px

.Dropdown.Secundary .DropdownContent .Button
    padding-left: 24px

@keyframes grow
  0%
    width: 0%
    left: 0%

  100%
    width: 60%
    left: -30%

@keyframes spin 
    0%
        transform: rotate(0deg)
    
    100%
        transform: rotate(-180deg)

@keyframes fadeIn
  0%
    opacity: 0

  100%
    visibility: visible
    opacity: 1

@keyframes fadeOut
  0%
    opacity: 1

  100%
    display: none
    visibility: hidden
    opacity: 0

@media screen and ( max-width: 1370px )
    .Dropdown.Primary .DropdownContentIn
        max-width: 940px
    
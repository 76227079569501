@import ../../../base/config/Config

.NavAndBannerArea
    position: sticky
    top: 0
    z-index: 100
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

.NavAndBannerArea.Primary
    background-color: var(--blue)

.NavAndBannerArea.Secundary
    background-color: var(--white)
    box-shadow: 0px 1px 4px #00000029

.NavBanner
    display: inline-flex
    align-items: center
    justify-content: space-between
    padding: 16px
    background-color: var(--yellow)

.socialMediaDay
    display: flex
    align-items: baseline
    justify-content: center
    flex-wrap: wrap
    background-color: #dc53ab
    padding: 10px 16px
    max-width: 1556px
    margin: 24px 24px 14px 24px
    border-radius: 8px
    width: calc( 100% - 48px )

.socialMediaDay.Brinfer
    background-color: #FFBC60

.socialMediaDayButton
    background-color: #512DA8
    color: #FFFFFF
    text-transform: uppercase
    font: normal normal medium 14px/17px var(--roboto)
    padding: 10px 16px
    border-radius: 18px
    margin-left: 16px

.socialMediaDayTextArea
    color: white

.socialMediaDayTextArea.Brinfer
    color: #000000

.socialMediaDayButton.Brinfer
    background-color: #D60153

.socialMediaDayTextArea.TikTok
    display: flex
    align-items: center
    color: #FFFFFF

.socialMediaDay.TikTok
    position: relative
    background-color: #000000

.socialMediaDayButton.TikTok
    background-color: #25F4EE
    color: #000000
    font-weight: 500
    font-size: 14px

.NavbarImage
    position: absolute
    left: 16px
    top: 50%
    transform: translateY(-50%)

.navBarTag
    padding: 2px 16px
    text-transform: uppercase
    color: #FFFFFF
    font-size: 16px
    font-weight: bold
    background: #FE2C55
    border-radius: 144px
    opacity: 1
    margin-right: 24px

.Navbar
    position: relative
    height: 92px
    width: 100%

.NavbarContent
    width: 100%
    max-width: 1365px
    display: inline-flex
    justify-content: space-between

.NavbarLogo
    margin-top: 2px

.Navbar.Primary .NavbarLogo
    margin-right: 10px

.leftButtonArea
    display: inline-flex
    width: auto
    padding: 24px 0px
    float: left

.leftButtonArea > *
    display: inline-block
    padding-left: 18px
    padding-right: 18px

.rightButtonArea
    display: inline-flex
    width: auto
    padding: 24px 0px
    float: right

.rightButtonArea > *
    display: inline-block
    padding: 0 10px

.hamburgerArea
    position: absolute
    visibility: collapse
    left: 0

.FirstDropdownContent
    width: 1141px
    margin-top: 21px
    display: flex
    position: relative
    left: 80px

.FirstDropdownContentLeft
    float: left
    text-align: left
    padding-top: 24px
    padding-left: 36px
    height: 100%
    width: 894px
    background-color: var(--white)
    box-shadow: 0px 9px 36px #00000029
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px

.FirstDropdownContentLeft > *
    margin-right: 16px
    margin-bottom: 24px

.FirstDropdownContentLeftContent
    text-align: left
    vertical-align: top
    display: inline-block
    width: 260px

.FirstDropdownContentLeftMessage .SmallText
    line-height: 1.25

.FirstDropdownContentLeftImage
    position: relative
    width: 32px
    height: 32px
    background: var(--white)
    margin-bottom: 3px

.FirstDropdownContentLeftImage img
    width: 100%
    height: 100%

.NavbarNewTextArea
    position: absolute
    left: 40px
    bottom: 0px

.FirstDropdownContentLeftTitle
    margin-bottom: 4px

.FirstDropdownContentLeftTitle .SmallText.Bigger
    font-size: 16px

.FirstDropdownContentSpecialContent
    display: flex
    flex-direction: column
    border-radius: 4px 0px 0px 4px
    background: var(--blue)
    padding: 16px
    position: absolute
    left: -190px
    height: 100%
    text-align: start
    align-items: start
    width: 200px
    box-sizing: border-box
    border: 1px solid var(--white)


.FirstDropdownContent .Button
    font-size: 14px
    font-family: Roboto
    font-weight: 500
    line-height: 32px
    padding: 0px
    margin-top: 24px

.FirstDropdownContentRight
    background-color: var(--yellow)
    border-radius: 0px 4px 4px 0px
    height: 100%
    position: absolute
    right: 0
    width: 247px
    float: right
    box-sizing: border-box

.FirstDropdownContentRightTitle
    margin-top: 24px
    float: left
    text-align: left
    margin-left: 24px
    margin-right: 24px

.FirstDropdownContentRightTitle .Title
    line-height: 1.25
    font-size: 28px

.FirstDropdownContentRightButton
    position: absolute
    bottom: 25px
    width: calc(100% - 94px)
    margin-left: 24px

.FirstDropdownContentRightButton a > *
    width: 100%

.SecondDropdownContent
    text-align: left
    margin-top: 21px
    padding-left: 24px
    padding-top: 25px
    padding-bottom: 36px
    padding-right: 50px
    background-color: var(--white)
    width: 100%
    border-radius: 4px
    box-shadow: 0px 9px 36px #00000029
    z-index: 1

.SecondDropdownContentButton .Button
    font-size: 18px
    padding: 0px 0px
    margin-right: 10px

.SecondDropdownContentButton
    padding-top: 11px
    padding-bottom: 11px
    &:hover
        color: var(--blue)

.SecondDropdownContentButton > *
    display: flex

.NavBarAcademyContent > *
    display: inline-block

.NavBarAcademyTextArea
    position: relative
    margin-left: -15px
    width: auto
    max-height: 25px
    vertical-align: middle
    line-height: 24px
    background: #EF5350
    border-radius: 50px

.NavBarAcademyText
    margin: 0
    height: 25px
    line-height: 25px
    font-size: 16px
    padding: 0px 8px
    font-weight: bold
    text-transform: uppercase
    font-family: var(--sofiaPro)
    color: var(--white)

@media screen and ( max-width: 1370px )
    .FirstDropdownContent
        width: 940px

    .FirstDropdownContentLeft
        float: left
        width: 664px

    .FirstDropdownContentRight
        margin-right: 44px

@media screen and ( max-width: 1432px )
    .leftButtonArea
        margin-left: 24px

    .leftButtonArea > *
        padding-left: 5px
        padding-right: 5px

    .rightButtonArea
        margin-right: 24px

    .rightButtonArea > *
        padding: 0 10px

@media screen and ( max-width: 1220px )

    .leftButtonArea
        position: absolute
        left: -200%
        visibility: collapse

    .rightButtonArea
        position: absolute
        left: -200%
        visibility: collapse

    .hamburgerImageArea
        float: left
        margin-left: 24px
        margin-top: -18px

    .navLinkArea
        position: absolute
        visibility: collapse

    .hamburgerArea
        position: absolute
        right: 0%
        top: 48px
        visibility: visible
        z-index: 10

    .Menuline
        width: 30px
        height: 3px
        margin: 5px
        border-radius: 20px

    .Navbar.Primary .Menuline
        background: var(--white)

    .Navbar.Secundary .Menuline
        background: var(--black)

    .Navbar.Primary .menu.open > *
        background: var(--black)

    .menu
        position: absolute
        cursor: pointer
        right: 24px
        transform: translate(-5%, -50%)
        z-index: 1

    .menuLink
        position: fixed
        background-color: var(--white)
        height: 100%
        width: 100%
        bottom: 0
        clip-path: circle(100px at 90% 0%)
        -webkit-clip-path: circle(100px at 90% 0%)
        transition: clip-path 1s ease-out
        visibility: collapse

    .menuLink > *
        visibility: hidden
        display: none

    .navImage
        z-index: 2
        position: absolute
        left: 24px
        top: 30px

    .navContent
        position: relative
        width: 100%
        height: 100%
        max-height: 800px

    .navLink
        display: flex
        align-items: center
        flex-direction: column
        position: absolute
        text-align: center
        margin-top: 97px
        z-index: 0
        height: calc(100% - 224px)
        width: 100%
        overflow-y: auto

    .navLink > *
        display: block

    .menuLink.open
        clip-path: circle(150vw)
        -webkit-clip-path: circle(150vw)
        visibility: visible

    .menuLink.open > *
        visibility: visible
        display: block

    .DropdownContent > *
        display: block

    .navLogin
        position: absolute
        bottom: 24px
        width: 100%
        background-color: var(--white)

    .navLoginButton
        display: inline-block
        width: 100%
        max-width: 400px
        margin-top: 0px
        margin-left: auto
        margin-right: auto
        z-index: 2

    .navLoginButton a > *
        display: block
        width: auto
        max-width: 400px
        margin-left: 24px
        margin-right: 24px

@media screen and ( max-width: 916px )
    .socialMediaDay
        flex-direction: column
        align-items: center

    .socialMediaDayTextArea
        display: flex
        flex-direction: column

    .socialMediaDayButton
        margin-left: 0px
        margin-top: 16px
        width: 100%
        box-sizing: border-box

    .NavbarImage
        left: 50%
        top: 14px
        transform: translateX(-50%)

    .navBarTag
        margin-top: 52px
        margin-right: 0px
        margin-bottom: 16px

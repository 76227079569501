@import ../../../../../base/config/Config

.OpenSocialArea
    background: var(--blue)
    padding: 76px 24px

.OpenSocialImagesArea
    display: flex
    justify-content: center
    width: 100%
    align-items: flex-start
    margin: 76px 0px

.OpenSocialImages
    display: flex
    flex-wrap: wrap
    justify-content: flex-start

.OpenSocialImage
    height: 200px

.OpenSocialImages > *
    margin-left: 20px

.OpenSocialSmallImage
    display: none

@media screen and ( max-width: 800px )
    .OpenSocialSmallImage
        display: flex

    .OpenSocialImages,
    .OpenSocialImage
        display: none

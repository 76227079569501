
.AndieArea
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    padding: 88px 24px

.AndieContentArea
    display: flex
    align-items: center
    justify-content: space-between
    max-width: 1400px
    width: 100%

.AndieLeftArea
    max-width: 690px
    width: 100%
    padding-right: 8px

.AndieMessageArea
    display: flex
    flex-direction: column
    align-items: flex-start

.AndieMessageArea img
    margin-bottom: 24px

.AndieMessageArea > *
    text-align: left
    margin-bottom: 32px

.AndieButtonArea,
.AndietextArea
    display: flex
    justify-content: left
    margin-bottom: 32px

.AndieButtonArea a
    margin-right: 12px

.AndieText
    margin-right: 24px

.AndieText img
    margin-right: 8px

.AndieRightArea
    max-width: 600px
    width: 100%

@media screen and ( max-width: 970px  )
    .AndieContentArea
        flex-direction: column

@import ../../../base/config/Config

.Message
    display: inline-block
    position: relative
    font-weight: Regular
    font-family: var(--openSans)        
    white-space: pre-line

.Message.Giant
    font-size: 24px

.Message.Bigger
    font-size: 20px

.Message.Big
    font-size: 18px

.Message.Medium
    font-size: 16px

.Message.Small
    font-size: 10px

.Message.Primary
    color: var(--white)

.Message.PrimaryBold
    color: var(--white)
    font-weight: 700

.Message.Secundary
    color: var(--black)

.Message.Tertiary
    color: var(--blue)

.Message.Agreement
    color: var(--darkGrey)

.Message.Fourth
    color: var(--darkerGrey)

.Message.Witness
    color: var(--black)
    font-family: var(--chapman)

.Message.WitnessSecundary
    color: var(--white)
    font-family: var(--chapman)

.Message.PlanBox
    color: var(--black)
    font-family: var(--sofiaPro)
@font-face
    src: url("montserrat500.eot") format("eot")
    font-family: 'Montserrat'
    font-weight: 500
    font-display: swap

@font-face
    src: url("montserrat500.svg") format("svg")
    font-family: 'Montserrat'
    font-weight: 500
    font-display: swap

@font-face
    src: url("montserrat500.ttf") format("ttf")
    font-family: 'Montserrat'
    font-weight: 500
    font-display: swap

@font-face
    src: url("montserrat500.woff") format("woff")
    font-family: 'Montserrat'
    font-weight: 500
    font-display: swap

@font-face
    src: url("montserrat500.woff2") format("woff2")
    font-family: 'Montserrat'
    font-weight: 500
    font-display: swap

@font-face
    src: url("montserratRegular.eot") format("eot")
    font-family: 'Montserrat'
    font-weight: 400
    font-display: swap

@font-face
    src: url("montserratRegular.svg") format("svg")
    font-family: 'Montserrat'
    font-weight: 400
    font-display: swap

@font-face
    src: url("montserratRegular.ttf") format("ttf")
    font-family: 'Montserrat'
    font-weight: 400
    font-display: swap

@font-face
    src: url("montserratRegular.woff") format("woff")
    font-family: 'Montserrat'
    font-weight: 400
    font-display: swap

@font-face
    src: url("montserratRegular.woff2") format("woff2")
    font-family: 'Montserrat'
    font-weight: 400
    font-display: swap

.AndieOutsideBox
    background: linear-gradient(90deg, #D72B2D 0%, #D8302F 0.01%, #E27547 22.75%, #EDB046 49.67%, #62E1DF 76.59%, #34435B 100%)
    border-radius: 16px
    padding: 4px

.AndieBox
    height: 677px
    border-radius: 16px
    display: flex
    flex-direction: column
    justify-content: flex-start
    overflow: hidden

.AndieBoxImageArea
    border-radius: 16px 16px 0px 0px
    background-color: white

.AndieBoxImage
    margin: 18px 16px
    float: left

.AndieBackgroundBox
    border-radius: 0px 0px 16px 16px
    background: #F5F5F5
    height: 100%
    overflow: auto
    overflow-anchor: auto

.AndieBackgroundContentBox
    margin: 16px

.AndieTopContent
    display: flex
    padding: 16px
    flex-direction: column
    align-items: flex-start
    gap: 8px
    border-radius: 16px
    border: 1px solid #DEDEDE
    background: white
    background: #FFF

.AndieTopFilterArea,
.AndieTopFilter
    display: flex
    flex-wrap: wrap

.AndieTopFilter > span
    text-align: left

.AndieTopFilter
    margin-right: 12px

.AndieResultBottomBoxButtonOutsideArea,
.AndieBackgroundBoxButtonOutsideArea
    display: flex
    align-items: center
    flex-direction: column
    width: 100%
    position: relative

.TryImageArea
    position: absolute
    top: -86px
    width: 100%
    height: 72px

.TryImageAreaUp
    height: 72px
    display: flex
    justify-content: right

.TryImageAreaUp img
    height: 72px

.AndieBackgroundBoxButtonArea
    padding: 16px
    border-radius: 0px 0px 16px 16px
    background: linear-gradient(90deg, #D72B2D 0%, #D8302F 0.01%, #E27547 22.75%, #EDB046 49.67%, #62E1DF 76.59%, #34435B 100%)
    width: 100%
    box-sizing: border-box

.AndieBackgroundBoxBackgroundOutsideButton
    background: linear-gradient(90deg, #D72B2D 0%, #D8302F 0.01%, #E27547 22.75%, #EDB046 49.67%, #62E1DF 76.59%, #34435B 100%)
    border-radius: 24px
    padding: 1px

.AndieBackgroundBoxButton
    width: 100%
    box-sizing: border-box
    display: flex
    justify-content: center
    padding: 10px 16px
    background: white
    border-radius: 24px
    cursor: pointer

.AndieBackgroundBoxButton img
    margin-right: 8px

.AndieResultBoxOutsideArea
    position: relative
    display: flex
    align-items: flex-start
    margin-top: 24px
    overflow: hidden

.AndieResultBoxOutsideArea.User
    display: flex
    justify-content: right

.AndieResultBoxOutsideArea.SameBubble
    margin-top: 4px

.AndieResultBoxArea
    display: flex
    align-items: flex-start
    animation: marginTop 500ms

@keyframes marginTop
    0%
        position: relative
        top: 124px
    100%
        position: relative
        top: 0px

@keyframes marginTopSameBubble
    0%
        position: relative
        top: 104px
    100%
        position: relative
        top: 0px

.AndieResultBoxArea.SameBubble
    display: flex
    align-items: flex-end
    animation: marginTopSameBubble 500ms

.AndieResultBoxArea.User
    justify-content: right

.AndieResultTopBoxArea
    display: flex
    padding: 6px 12px 6px 8px
    justify-content: center
    align-items: center
    gap: 8px
    border-radius: 8px
    border: 1px solid #DEDEDE
    background: #F5F5F5

.AndieResultBottomBoxOutsideArea
    background: linear-gradient(90deg, #D72B2D 0%, #D8302F 0.01%, #E27547 22.75%, #EDB046 49.67%, #62E1DF 76.59%, #34435B 100%)
    border-radius: 0px 16px 16px 16px
    padding: 1px

.AndieResultBottomBoxOutsideArea.User
    background: #DEDEDE
    padding: 0px
    border-radius: 16px 0px 16px 16px

.AndieResultBottomBoxArea.User
    border: 1px solid #DEDEDE
    background: white
    border-radius: 16px 0px 16px 16px

.AndieResultBottomBoxArea.SameBubble,
.AndieResultBottomBoxOutsideArea.SameBubble
    border-radius: 16px

.AndieResultBottomBoxArea
    display: flex
    padding: 8px 16px
    flex-direction: column
    align-items: flex-start
    border-radius: 0px 16px 16px 16px
    background: #f1eef8

.AndieResultBottomBoxArea span
    text-align: left

.andieSmallImage
    width: 18px
    height: 18px

.AndieResultBottomBoxButtonArea
    display: flex
    width: 100%
    justify-content: flex-end
    margin-top: 8px

.AndieResultBottomBoxOutsideButton
    background: linear-gradient(90deg, #D72B2D 0%, #D8302F 0.01%, #E27547 22.75%, #EDB046 49.67%, #62E1DF 76.59%, #34435B 100%)
    border-radius: 8px
    padding: 1px

.AndieResultBottomBoxButton
    display: flex
    padding: 6px 12px 6px 8px
    justify-content: center
    align-items: center
    gap: 8px
    border-radius: 8px
    background: #FFF
    cursor: pointer

.AndieResultBottomBoxDivider
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    align-self: stretch
    height: 1px
    box-sizing: border-box
    background: #DEDEDE
    margin: 8px 16px

.AnimatedButton
    top: 124px
    position: relative
    animation: marginTop 500ms
    animation-delay: 500ms
    animation-fill-mode: forwards

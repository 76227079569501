@font-face
    src: url("openSansLatin600.woff2") format("woff2")
    font-family: 'Open Sans'
    font-weight: 600
    font-display: swap

@font-face
    src: url("openSansLatin700.woff2") format("woff2")
    font-family: 'Open Sans'
    font-weight: 700
    font-display: swap

@font-face
    src: url("openSansLatin800.woff2") format("woff2")
    font-family: 'Open Sans'
    font-weight: 800
    font-display: swap

@font-face
    src: url("openSansLatinItalic.woff2") format("woff2")
    font-family: 'Open Sans'
    font-weight: 400
    font-display: swap

@font-face
    src: url("openSansLatinRegular.woff2") format("woff2")
    font-family: 'Open Sans'
    font-weight: 400
    font-display: swap

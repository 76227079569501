@import ../../resources/fonts/bebasNeue/BebasNeue.sass
@import ../../resources/fonts/chapman/Chapman.sass
@import ../../resources/fonts/openSans/OpenSans.sass
@import ../../resources/fonts/roboto/Roboto.sass
@import ../../resources/fonts/sofiaPro/SofiaPro.sass
@import ../../resources/fonts/monsterrat/Monstserrat.sass
@import ../../resources/fonts/halyard/Halyard.sass
@import ../../resources/fonts/neueHaasGrotesk/NeueHaasGrotesk.sass

\:root
    /* Colors */
    --blue: #0F31F2
    --pixkieBlue: #0A5C9C
    --LightDarkerBlue: #0B65F5
    --disabledColor: #EEEEEE
    --lightBlue: #2CEAFF
    --white: #FFFFFF
    --darkWhite: #FAFAFA
    --darkerWhite: #F5F5F5
    --lightGrey: #ECEFFE
    --black: #212121
    --yellow: #F2DB66
    --purple: #7842F5
    --lightPurple: #7A18B7
    --darkPurple: #331C69
    --darkerPurple: #46278F
    --grey: #DEDEDE
    --darkGrey: #9E9E9E
    --darkerGrey: #757575
    --red: #FF0000
    --infoPlan: #BDBDBD
    --facebook: #1877F2
    --facebookads: #677B8C
    --instagram: #CD486B
    --twitter: #1DA1F2
    --linkedin: #0077B5
    --youtube: #FF0000
    --email: #0F31F2
    --other: #7842F5
    --acedamyPurple: #211A70
    --andiePurple: #391F76

    /* Fonts */
    --bebasNeue: 'BebasNeue'
    --chapman: 'Chapman'
    --openSans: 'Open Sans'
    --roboto: 'Roboto'
    --sofiaPro: 'sofiaPro'
    --montserrat: 'Montserrat'
    --halyard: 'Halyard'

    /* Icons */
    --downArrow: url('../../resources/icons/caret_down.svg')
    --chevronDown: url('../../resources/icons/chevronDown.svg')
    --chevronUp: url('../../resources/icons/chevronUp.svg')
    --chevronGrey: url('../../resources/icons/chevronGrey.svg')
    --checkBlue: url('../../resources/icons/checkBlue.svg')
    --checkGrey: url('../../resources/icons/checkGrey.svg')
    --checkLightGrey: url('../../resources/icons/checkLightGrey.svg')
    --checkPurple: url('../../resources/icons/checkPurple.svg')
    --pixkieBottomPic: url('../../resources/icons/pixkie2.webp')
    --academyBalls: url('../../resources/icons/Group 683.svg')
    --academyImage: url('../../resources/icons/Rectangle5629.webp')
    --onTourImage: url('../../resources/icons/ontour_menu.webp')
    --inputButton: url('../../resources/icons/attachment.svg')

    /* Images */
    --academyImage: url(../../resources/images/academy/academyImage.png)
    --mobileAcademyImage: url(../../resources/images/academy/mobileAcademyImage.png)

@font-face
    src: url("robotoLatin300.woff2") format("woff2")
    font-family: 'Roboto'
    font-weight: 300
    font-display: swap

@font-face
    src: url("robotoLatin500.woff2") format("woff2")
    font-family: 'Roboto'
    font-weight: 500
    font-display: swap

@font-face
    src: url("robotoLatin700.woff2") format("woff2")
    font-family: 'Roboto'
    font-weight: 700
    font-display: swap

@font-face
    src: url("robotoLatin900.woff2") format("woff2")
    font-family: 'Roboto'
    font-weight: 900
    font-display: swap

@font-face
    src: url("robotoLatinRegular.woff2") format("woff2")
    font-family: 'Roboto'
    font-weight: 400
    font-display: swap


@import ../../../base/config/Config

.Title
    display: inline-block
    position: relative
    font-family: var(--sofiaPro)
    font-weight: 700
    white-space: pre-line

.Title.Giant
    font-size: 96px

.Title.Bigger
    font-size: 76px

.Title.Big
    font-size: 48px

.Title.Medium
    font-size: 36px

.Title.Small
    font-size: 24px

.Title.Primary
    color: var(--white)

.Title.PrimaryRegular
    color: var(--white)
    font-weight: 400

.Title.Secundary
    color: var(--black)

.Title.SecundaryRegular
    color: var(--black)
    font-weight: 400

.Title.Tertiary
    color: var(--blue)

.Title.Fourth
    color: var(--darkPurple)

.Title.Disabled
    color: var(--disabledColor)

.Title.Academy
    font-size: 85px
    font-family: var(--bebasNeue)
    font-weight: 700
    color: var(--lightBlue)
    text-shadow: 4px 4px 0px var(--lightPurple)

.Title.Facebook
    color: var(--facebook)

.Title.Facebook.Ads
    color: var(--facebookads)

.Title.Instagram
    color: var(--instagram)

.Title.Twitter
    color: var(--twitter)

.Title.Linkedin
    color: var(--linkedin)

.Title.Youtube
    color: var(--youtube)

.Title.Email
    color: var(--email)

.Title.Other
    color: var(--other)

@media screen and ( max-width: 748px )
    .Title.Academy
        font-size: 56px
        font-weight: 500

    .Title.Big
        font-size: 32px

    .Title.Medium
        font-size: 24px

    .Title.Bigger
        font-size: 32px

    .Title.Giant
        font-size: 62px

    .Title.Small
        font-size: 16px



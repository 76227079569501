@font-face
    font-family: 'Halyard Display Book'
    src: url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/1001006/Halyard_Display_Book.ttf')

@font-face
    font-family: 'Halyard Display Bold'
    src: url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/1001008/Halyard_Display_Bold.ttf')

@font-face
    font-family: 'Halyard Micro Bold'
    src: url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/1001009/Halyard_Micro_Bold.ttf')

@font-face
    font-family: 'Halyard Text Medium'
    src: url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/1001000/Halyard_Text_Medium.ttf')

@font-face
    font-family: 'Halyard Text Light'
    src: url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/1001002/Halyard_Text_Light.ttf')

@font-face
    font-family: 'Halyard Text Book'
    src: url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/1001003/Halyard_Text_Book_Regular.ttf')

@font-face
    font-family: 'Halyard Display Medium'
    src: url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/1001005/Halyard_Display_Medium.ttf')

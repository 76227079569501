@import ../../../base/config/Config

.NewTextArea
    max-height: 15px
    vertical-align: middle
    line-height: 12px
    background: #EF5350
    border-radius: 50px
    display: inline-flex

.NewTextArea.Andie
    background: linear-gradient(90deg, #D72B2D 0%, #D8302F 0.01%, #E27547 22.75%, #EDB046 49.67%, #62E1DF 76.59%, #34435B 100%)

.NewText
    margin: 0
    height: 12px
    line-height: 12px
    font-size: 12px
    padding: 2px 16px
    font-weight: bold
    text-transform: uppercase
    font-family: var(--sofiaPro)
    color: var(--white)

@import ../../../base/config/Config

.SmallText
    display: inline-block
    position: relative
    white-space: pre-line

.SmallText.Blue
    color: var(--blue)!important

.SmallText.Titan
    font-size: 28px

.SmallText.colossal
    font-size: 22px

.SmallText.Giant
    font-size: 20px

.SmallText.Bigger
    font-size: 18px

.SmallText.Default
    font-size: 16px

.SmallText.Big
    font-size: 14px

.SmallText.Medium
    font-size: 12px

.SmallText.Small
    font-size: 10px

.SmallText.Primary
    color: var(--white)

.SmallText.Secundary
    color: var(--black)

.SmallText.Tertiary
    color: var(--darkPurple)

.SmallText.Fourth
    color: var(--blue)

.SmallText.Fifth
    color: var(--purple)

.SmallText.Six
    color: var(--pixkieBlue)

.SmallText.Seventh
    color: var(--darkGrey)

.SmallText.Eight
    color: var(--darkerGrey)

.SmallText.Ninth
    color: var(--andiePurple)

.SmallText.Gradient
    background-color: red
    background-image: linear-gradient(90deg, #D72B2D 0%, #D8302F 0.01%, #E27547 22.75%, #EDB046 49.67%)
    background-size: 100%
    background-repeat: repeat
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    -moz-background-clip: text
    -moz-text-fill-color: transparent

.SmallText.Error
    color: var(--red)

.SmallText.Facebook
    color: var(--facebook)

.SmallText.Facebook.Ads
    color: var(--facebookads)

.SmallText.Instagram
    color: var(--instagram)

.SmallText.Twitter
    color: var(--twitter)

.SmallText.Linkedin
    color: var(--linkedin)

.SmallText.Youtube
    color: var(--youtube)

.SmallText.Email
    color: var(--email)

.SmallText.Other
    color: var(--other)

.SmallText.Disabled
    color: var(--infoPlan)

.SmallText.OpenSans
    font-family: var(--openSans)

.SmallText.OpenSansSemiBold
    font-family: var(--openSans)
    font-weight: 600

.SmallText.OpenSansBold
    font-family: var(--openSans)
    font-weight: bold

.SmallText.OpenSansItalic
    font-family: var(--openSans)
    font-style: italic

.SmallText.Roboto
    font-family: var(--roboto)

.SmallText.Roboto
    font-family: var(--roboto)

.SmallText.RobotoBold
    font-family: var(--roboto)
    font-weight: 500

.SmallText.SofiaProBold
    font-family: var(--sofiaPro)
    font-weight: 600

.SmallText.SofiaProRegular
    font-family: var(--sofiaPro)

.SofiaProItalic
    font-family: var(--sofiaPro)
    font-style: italic

.SmallText.SofiaProLight
    font-family: var(--sofiaPro)
    font-weight: 300

.SmallText.SofiaProLightItalic
    font-family: var(--sofiaPro)
    font-weight: 300
    font-style: italic

.SmallText.Montserrat
    font-family: var(--montserrat)
    font-weight: 700

.SmallText.HalyardMicroBold
    font-family: Halyard Micro Bold
    font-weight: bold
